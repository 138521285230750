import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "components/Buttons/Button"
import ButtonGroup from "components/Buttons/ButtonGroup"

import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout language="en">
    <SEO
      title="404: Page Not Found"
      description="Oooops, it looks like the page you’re trying to reach is missing or no longer available. Please check the URL you typed for spelling and capitalization. If you’re still having problems, please visit the hompeage."
      robots="noindex"
    />

    <div className="joshua-tree-content page-404">
      <div className="columns is-mobile space-alone is-hidden-tablet">
        <div className="column is-5-mobile is-3-tablet"></div>
        <div className="column">
          <ImageMeta
            cloudName="nuvolum"
            publicId="/NUVO/space-alone.png"
            width="auto"
          ></ImageMeta>
        </div>
        <div className="column is-5-mobile is-3-tablet"></div>
      </div>

      <div className="text-404">
        <div className="columns is-mobile is-vcentered">
          <div className="column is-2-mobile is-3-tablet is-3-desktop is-4-widescreen"></div>
          <div className="column is-8-desktop is-9-widescreen">
            <ImageMeta
              cloudName="nuvolum"
              publicId="/NUVO/space-alone.png"
              width="auto"
              className="tablet-space-alone is-hidden-mobile is-hidden-desktop"
            ></ImageMeta>
            <div className="text">
              <h1>404</h1>
              <h2>Page Not Found</h2>
              <h3>
                Solitude can be peaceful and inspiring unless you’re stuck on an
                asteroid.
              </h3>
              <p>
                Oooops, it looks like the page you’re trying to reach is missing
                or no longer available. Please check the URL you typed for
                spelling and capitalization. If you’re still having problems,
                please visit the homepage.
              </p>
              <ButtonGroup>
                <Button
                  className="standard-button mt-2"
                  buttonText="Back Home"
                  href="/"
                  contained
                />
              </ButtonGroup>
            </div>
          </div>
          <div className="column is-hidden-touch is-3-desktop is-2-widescreen"></div>
          <div className="column is-hidden-touch">
            <ImageMeta
              cloudName="nuvolum"
              publicId="/NUVO/space-alone.png"
              width="auto"
              className=""
            ></ImageMeta>
          </div>
          <div className="column is-2-mobile is-1-tablet is-4-desktop"></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
